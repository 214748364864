import { cn } from "~/core/styles/helpers";
import { Container } from "~/coreUI/Layout/Container/Container";
import { TextBlock } from "../TextBlock/TextBlock";
import { type ImageField } from "@prismicio/types";
import { type TextImageSlice } from "prismicio-types";
import { Image } from "~/coreUI/Content/Image/Image";
import { bgStyles } from "~/core/styles/cvaStyles";
import { motion } from "framer-motion";
import { animationBaseProps, variants } from "~/core/styles/animations";

interface TextImageProps {
  slice: TextImageSlice;
}

export const TextImage = (props: TextImageProps) => {
  const {
    title,
    paragraph,
    color,
    bgColor = "default",
    image,
    type,
  } = props.slice.primary;

  return (
    <Container
      className={cn(
        "overflow-hidden px-0 sm:px-0 md:overflow-hidden md:px-16 lg:px-16 xl:px-28",
        color,
        bgStyles({ bgColor })
      )}
      innerClassName="flex flex-col md:grid md:grid-cols-2 gap-24 md:gap-12 items-center md:overflow-visible"
      yPadding="lg"
      xPadding="never"
    >
      <TextBlock paragraph={paragraph} title={title} />

      <div className="relative w-full md:w-auto">
        <div className="items-center justify-center self-stretch overflow-hidden  py-3 md:flex md:w-auto md:overflow-visible md:px-8">
          {type === "bigCover" && <BigCover image={image} />}
          {type === "fill" && <Fill image={image} />}
        </div>
      </div>
    </Container>
  );
};

interface ImageProps {
  image: ImageField;
}

const BigCover = ({ image }: ImageProps) => {
  return (
    <div
      className={
        "relative h-[750px] w-full shrink-0 sm:h-[1000px] md:absolute md:left-0 md:h-auto md:w-auto"
      }
    >
      <motion.div
        className="z-10 max-w-[700px] md:hidden"
        {...animationBaseProps}
        variants={variants.fadeIn}
      >
        <Image
          image={image}
          className=" absolute h-[24rem] shrink-0 rounded-md object-cover sm:h-[30rem]"
          layout="fill"
          alt={image.alt ?? ""}
        />
      </motion.div>

      <motion.div
        className="z-10  hidden h-[780px] w-[950px] md:!block"
        {...animationBaseProps}
        variants={variants.fromRight}
      >
        <Image
          image={image}
          className=" absolute right-5 h-8 w-full shrink-0 rounded-md object-cover"
          layout="intrinsic"
          alt={image.alt ?? ""}
        />
      </motion.div>
    </div>
  );
};

const Fill = ({ image }: ImageProps) => {
  return (
    <div className="relative z-10 mx-auto w-full max-w-[30rem] px-4">
      <motion.div {...animationBaseProps} variants={variants.fromRight}>
        <Image image={image} alt={image.alt ?? ""} />
      </motion.div>
    </div>
  );
};
