import { type Content } from "@prismicio/client";
import { type SliceComponentProps } from "@prismicio/react";
import { TextImage as Component } from "~/coreUI/Content/Slices/TextImage/TextImage";

export type TextImageProps = SliceComponentProps<Content.TextImageSlice>;

const TextImage = ({ slice }: TextImageProps): JSX.Element => {
  return (
    <div
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <Component slice={slice} />
    </div>
  );
};

export default TextImage;
